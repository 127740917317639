import Vue from 'vue'
import axios from 'axios'
import configSeting from '@/common/js/config.js'




function sendRequest(config, { data_format = true } = {}) {
    axios.defaults.baseURL = '/api'

    //创建axios实例/添加全局及地址/添加全局请求超时时间
    let instance = axios.create({
        baseURL: configSeting.baseUrl,
        timeout: 6000
    })



    // 请求拦截器
    // 当我们发送请求  拦截axios
    // 作用 : 我们可以利用axios拦截器进行一些操作
    // 比如 token
    instance.interceptors.request.use(function (config) {
        // console.log('%c [ config.url ]-25', 'font-size:13px; background:pink; color:#bf2c9f;', config.url)
        // if (config.url !== '/large-screen-login') {
        //     if (config.method === 'post' || config.method === 'POST') {
        //         localStorage.getItem('token') ? config.data.token = localStorage.getItem('token') : ''
        //     } else {
        //         localStorage.getItem('token') ? config.params.token = localStorage.getItem('token') : ''
        //     }
        // }
        //获取token 进行请求头加工
        // let token = localStorage.getItem('token')
        // if (token) {
        //     //加工请求头
        //     config.headers.Authorization = 'Bearer ' + token
        // }

        //一定要返回请求体
        return config
    })

    //响应拦截器  请求成功之后拦截
    instance.interceptors.response.use(function (result) {
        // console.log(JSON.parse(result))
        //请求过程没问题  后台返回的数据不健康
        //有问题暂时注释掉
        // if (!result.data.success) {
        //     Vue.$toast.error(result.data.message)
        //     return {}
        // }

        // 返回请求数据
        // return data_format ? result.data : result
        // return data_format ? JSON.parse(result) : JSON.parse(result)
        return result
    }, error => {
        httpErrorStatusHandle(error)
        return error
    });


    return instance(config)
}


// 处理异常的函数
function httpErrorStatusHandle(error) {
    let message = '';
    if (error.response) {
        // 有响应，但是状态码不对
        switch (error.response.status) {
            case 302: message = '接口重定向了！'; break;
            case 400: message = '参数不正确！'; break;
            case 401: message = '您未登录，或者登录已经超时，请先登录！'; break;
            case 403: message = '您没有权限操作！'; break;
            case 404: message = `请求地址出错: ${error.response.config.url}`; break; // 在正确域名下
            case 408: message = '请求超时！'; break;
            case 409: message = '系统已存在相同数据！'; break;
            case 500: message = '服务器内部错误！'; break;
            case 501: message = '服务未实现！'; break;
            case 502: message = '网关错误！'; break;
            case 503: message = '服务不可用！'; break;
            case 504: message = '服务暂时无法访问，请稍后再试！'; break;
            case 505: message = 'HTTP版本不受支持！'; break;
            default: message = '异常问题，请联系管理员！'; break
        }
    }
    if (error.message.includes('timeout')) {
        message = '网络请求超时！';
    }
    if (error.message.includes('Network')) {
        message = window.navigator.onLine ? '服务端异常！' : '您断网了！';
    }
    // 弹出错误提示
    new Vue().$message.error(message);
}

export default {
    sendRequest
}

// export default {
//     get(url, params) {
//         return myAxios({
//             url,
//             params
//         })
//     },
//     post(url, data) {
//         return myAxios({
//             url,
//             method: 'POST',
//             data
//         })
//     },
//     //更新头像
//     upload(url, file) { // file 文件对象
//         let formData = new FormData()
//         formData.append('file', file)
//         return myAxios({
//             url,
//             method: 'POST',
//             data: formData
//         })
//     },
//     //删除用户
//     del(url, data) {
//         return myAxios({
//             url,
//             method: 'delete',
//             data
//         })
//     }
// }
