import Vue from "vue";
import App from "./App.vue";
import sendRequest from "@/common/js/http.js";
import Vant from "vant";
import "vant/lib/index.css";
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import router from "./router/index.js";
import "amfe-flexible";
import * as THREE from "three";
import "animate.css";
import config from './common/js/config.js'

Vue.prototype.$config = config;
Vue.prototype.$api = sendRequest;
Vue.prototype.$bus = new Vue();
Vue.prototype.THREE = THREE;

Vue.config.productionTip = false;

Vue.use(ElementUI);
Vue.use(Vant);
new Vue({
  ...App,
  router,
  // render: (h) => h(App),
}).$mount("#app");
