import Vue from 'vue'
import VueRouter from 'vue-router'
Vue.use(VueRouter)
const routes = [
    {
        path: '/',
        name: 'qiandao',
        component: () => import('@/view/index/index.vue'),
        meta: {
            title: '签到'
        }
    },
    {
        path: '/yaodajiang',
        name: 'yaodajiang',
        component: () => import('@/view/yaodajiang.vue'),
        meta: {
            title: '摇大奖'
        }
    },
    {
        path: '/choujiang',
        name: 'choujiang',
        component: () => import('@/view/choujiang.vue'),
        meta: {
            title: '抽奖'
        }
    },
    {
        path: '/kaimuAndBimu',
        name: 'kaimuAndBimu',
        component: () => import('@/view/kaimuAndBimu.vue'),
        meta: {
            title: ''
        }
    },
]

const router = new VueRouter({
    routes
})

//登录页面token判断
router.beforeEach((to, from, next) => {
    //取出token继续判断
    // console.log('%c [to]: ', 'color: #bf2c9f; background: pink; font-size: 13px;', to)
    let token = localStorage.getItem('token');
    if (to.query.type && to.query.type == 0) {
        document.title = '开幕'
    } else if (to.query.type && to.query.type == 1) {
        document.title = '闭幕'
    } else {
        document.title = to.meta.title || ''
    }

    //进行网页的title添加

    next()

})


export default router
